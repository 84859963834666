import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import queryString from "query-string";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import { accountVerifyRequest } from "../../actions";
import moment from "moment";
import { SITE_TITLE, SITE_DESCRIPTION } from "../../lib/constants";
import LayoutFloating from "../../components/LayoutFloating";

const VerifyUser = (props) => {
  const { t } = useTranslation();
  const params =
    props.location &&
    props.location.search &&
    queryString.parse(props.location.search);

  const { success } = props.account_verify_response || {};

  if (params && props.account_verify_request_time == null) {
    // Hardcoding params so user can't try arbitrary fields
    const data = {
      user_id: params.user_id,
      timestamp: params.timestamp,
      signature: params.signature,
    };

    props.dispatch(accountVerifyRequest(data));
  }

  return (
    <Layout
      pageName="account-login"
      showHeader={false}
      siteTitle={t(SITE_TITLE)}
      siteDescription={t(SITE_DESCRIPTION)}
    >
      <Helmet>
        <title>{t(SITE_TITLE)}</title>
      </Helmet>
      <LayoutFloating>
        <div className="form-signin-groups">
          <h2 className="py-0 mt-0">
            {success ? t("Verified") : t("Verifying...")}
          </h2>
          <p className="pb-2 my-0">
            <small className="text-muted font-italic">
              {props.account_verify_request_time
                ? moment(props.account_verify_request_time).format(
                    "dddd, MMMM Do YYYY, h:mm a"
                  )
                : null}
            </small>
          </p>
          {success ? (
            <p className="alert alert-secondary">
              <Trans>
                Your account has been verified. Please log in to continue.
              </Trans>
            </p>
          ) : null}
          <div className="account-container-btn">
            <Button variant="primary" size="lg" href="/account/login" block>
              <Trans>Log In</Trans>
            </Button>
          </div>
        </div>
      </LayoutFloating>
    </Layout>
  );
};

export default connect((state) => state)(VerifyUser);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
